
import { Component, Prop, Mixins } from 'vue-property-decorator';
import mixinPage from '@/mixins/mixinPage';
import { Provide } from '@/library/provide';
import LTable from "@/components/l/Table.vue";
import LTableBody from "@/components/l/Table/Body.vue";
import FDate from "@/components/f/Date.vue";
import FSelect from "@/components/f/Select.vue";
import FTime from "@/components/f/Time.vue";
import FControl from "@/components/f/Control.vue";

@Component({
  components: {
    LTable,
    LTableBody,
    FDate,
    FSelect,
    FTime,
    FControl,
  }
})
export default class Main extends Mixins(mixinPage) {
  @Prop() remain_paid_holiday: number;
  @Prop() employee: any;
  @Prop() global_config: any;
  @Prop() employeely_config: any;

  get raw_object() {
    return this.employee;
  }
  get tmp_object() {
    return this.manager.object.employee;
  }
}
