
import { Component, Prop, Mixins } from 'vue-property-decorator';
import mixinPage from '@/mixins/mixinPage';
import EmployeeeachSelfGeneralNickname from "@/components/employeeeach/self/general/Nickname.vue";
import EmployeeeachSelfGeneralSex from "@/components/employeeeach/self/general/Sex.vue";
import EmployeeeachSelfGeneralBirthdate from "@/components/employeeeach/self/general/Birthdate.vue";
import EmployeeeachSelfGeneralMynum from "@/components/employeeeach/self/general/Mynum.vue";
import EmployeeeachSelfGeneralMail from "@/components/employeeeach/self/general/Mail.vue";
import EmployeeeachSelfGeneralZip from "@/components/employeeeach/self/general/Zip.vue";
import EmployeeeachSelfGeneralAddress from "@/components/employeeeach/self/general/Address.vue";
import EmployeeeachSelfGeneralTel from "@/components/employeeeach/self/general/Tel.vue";
import EmployeeeachSelfGeneralMobile from "@/components/employeeeach/self/general/Mobile.vue";

@Component({
  components: {
    EmployeeeachSelfGeneralNickname,
    EmployeeeachSelfGeneralSex,
    EmployeeeachSelfGeneralBirthdate,
    EmployeeeachSelfGeneralMynum,
    EmployeeeachSelfGeneralMail,
    EmployeeeachSelfGeneralZip,
    EmployeeeachSelfGeneralAddress,
    EmployeeeachSelfGeneralTel,
    EmployeeeachSelfGeneralMobile,
  }
})
export default class Main extends Mixins(mixinPage) {
  @Prop() employee: any;
  @Prop() global_config: any;

  get raw_object() {
    return this.employee;
  }
  get tmp_object() {
    return this.manager.object.employee;
  }

}
