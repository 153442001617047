import { render, staticRenderFns } from "./Dependantrow.vue?vue&type=template&id=d1d50f9c&scoped=true&lang=pug"
import script from "./Dependantrow.vue?vue&type=script&lang=ts"
export * from "./Dependantrow.vue?vue&type=script&lang=ts"
import style0 from "./Dependantrow.vue?vue&type=style&index=0&id=d1d50f9c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d1d50f9c",
  null
  
)

export default component.exports