
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import mixinPart from '@/mixins/mixinPart';
import { Inject } from '@/library/provide';
import LItem from "@/components/l/Item.vue";
import FText from "@/components/f/Text.vue";

@Component({
  components: {
    LItem,
    FText,
  }
})
export default class Main extends Mixins(mixinPart) {
  @Prop() employee_info: any;

  @Watch('is_edit')
  onChangeIsEdit(newval) {
    if (newval) {
      this.input_each_employee_info();
    }
  }

  input_each_employee_info() {
    let last_index = this.object.length - 1;
    if (last_index == -1) {
      this.object.push({freehead: null, content: null});
    } else if (this.object[last_index].freehead !== null && this.object[last_index].content !== null) {
      this.object.push({freehead: null, content: null});
    }

    last_index = this.object.length - 1;

    let index = null;
    this.object.forEach((each_object, i) => {
      if (last_index !== i && each_object.freehead === null && each_object.content === null) {
        index = i;
      }
    });

    if (index !== null) {
      this.object.splice(index, 1);
    }
  }
}
