
import { Component, Prop, Mixins } from 'vue-property-decorator';
import mixinPage from '@/mixins/mixinPage';
import * as util from '@/util';
import { Provide } from '@/library/provide';
import LContent from "@/components/l/Content.vue";
import LBread from "@/components/l/Bread.vue";
import FControl from "@/components/f/Control.vue";
import EmployeeeachLaborGeneralPack from "@/components/employeeeach/labor/general/Pack.vue";

@Component({
  components: {
    LContent,
    LBread,
    FControl,
    EmployeeeachLaborGeneralPack,
  }
})
export default class Main extends Mixins(mixinPage) {
  @Prop() employee: any;
  @Prop() global_config: any;
  @Prop() employeely_config: any;

  get raw_object() {
    return this.employee;
  }
  get tmp_object() {
    return this.manager.object.employee;
  }

  @Provide('employee_type')
  get employee_type() {
    return this.employee.type;
  }

  get mypc_type() {
    if (this.object.cookie && this.object.cookie == util.getLocalStorage('mypc')) {
      return 2;
    } else if (this.object.cookie) {
      return 1;
    } else {
      return 0;
    }
  }
}
