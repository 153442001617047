
import { Component, Prop, Mixins } from 'vue-property-decorator';
import mixinEmployeeEach from '@/mixins/mixinEmployeeEach';
import { Provide } from '@/library/provide';
import LContent from "@/components/l/Content.vue";
import LBread from "@/components/l/Bread.vue";
import LTable from "@/components/l/Table.vue";
import EmployeeeachBelongMainbranch from "@/components/employeeeach/belong/Mainbranch.vue";
import EmployeeeachBelongSubbranch from "@/components/employeeeach/belong/Subbranch.vue";
import EmployeeeachBelongShiftgroup from "@/components/employeeeach/belong/Shiftgroup.vue";
import EmployeeeachBelongPaymentgroup from "@/components/employeeeach/belong/Paymentgroup.vue";

@Component({
  components: {
    LContent,
    LBread,
    LTable,
    EmployeeeachBelongMainbranch,
    EmployeeeachBelongSubbranch,
    EmployeeeachBelongShiftgroup,
    EmployeeeachBelongPaymentgroup,
  }
})
export default class Main extends Mixins(mixinEmployeeEach) {
  created() {
    this.m.initEditManager({name: 'belong_main_branch'});
    this.m.initEditManager({name: 'belong_sub_branch'});
    this.m.initEditManager({name: 'belong_shiftgroup'});
    this.m.initEditManager({name: 'belong_paymentgroup'});
  }
}
