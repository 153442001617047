
import { Component, Mixins, Vue } from 'vue-property-decorator';
import mixinEmployeeEach from '@/mixins/mixinEmployeeEach';
import dialog from '@/vuex/dialog';
import EmployeeeachConfigConfig from "@/components/employeeeach/config/Config.vue";

@Component({
  components: {
    EmployeeeachConfigConfig,
  }
})
export default class Main extends Mixins(mixinEmployeeEach) {
  created() {
    this.m.initEditManager({name: 'config'});
  }

  update({id, tmp_objects}) {
    if (this.employeely.employee == 1 && tmp_objects.employee.is_use_employee_config == 0) {
      dialog.openConfirmDialog({title: '注意', msg: '例外設定を無効にしようとしています。<br>無効にした後に有効にすると会社の設定で上書きされ、現在の例外設定は上書きされます。<br>本当によろしいですか？', detail: '上書き後に元に戻すことはできません。', func: this.updateProcess, args: {id, tmp_objects}, exec_button: '実行する', cancel_button: '実行しない'});
    } else {
      this.updateProcess({id, tmp_objects}); 
    }
  }
  updateProcess({id, tmp_objects}) {
    if (tmp_objects.employee.is_use_employee_config == 0) {
      this.m.update({name: 'config_reset', args: {employee: tmp_objects.employee, employee_id: this.employee_id}});
    } else {
      this.m.update({name: 'config', args: {employee: tmp_objects.employee, employee_id: this.employee_id}});
    }
  }
}
