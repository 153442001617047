
  import { Component, Mixins } from "vue-property-decorator";
  import mixinEmployeeEach from "@/mixins/mixinEmployeeEach";
  import LContent from "@/components/l/Content.vue";
  import LBread from "@/components/l/Bread.vue";
  import EmployeeeachSelfFamilySpoucecard from "@/components/employeeeach/self/family/Spoucecard.vue";
  import EmployeeeachSelfFamilyDependantcard from "@/components/employeeeach/self/family/Dependantcard.vue";
  import LTable from "@/components/l/Table.vue";
  import LTableBody from "@/components/l/Table/Body.vue";
  import FControl from "@/components/f/Control.vue";
  import EmployeeeachSelfFamilySpoucerow from "@/components/employeeeach/self/family/Spoucerow.vue";
  import EmployeeeachSelfFamilyDependantrow from "@/components/employeeeach/self/family/Dependantrow.vue";

  @Component({
    components: {
      LContent,
      LBread,
      EmployeeeachSelfFamilySpoucecard,
      EmployeeeachSelfFamilyDependantcard,
      LTable,
      LTableBody,
      FControl,
      EmployeeeachSelfFamilySpoucerow,
      EmployeeeachSelfFamilyDependantrow,
    },
  })
  export default class Main extends Mixins(mixinEmployeeEach) {
    openSpouce(spouce_id: number) {
      this.query_push = { spouce_id, page: "spouce" };
    }
    openSpouceAdd() {
      this.page = "spouce_add";
    }
    openDependant(dependant_id: number) {
      this.query_push = { dependant_id, page: "dependant" };
    }
    openDependantAdd() {
      this.page = "dependant_add";
    }
  }
