
import { Component, Mixins } from 'vue-property-decorator';
import mixinEmployeeEach from '@/mixins/mixinEmployeeEach';
import EmployeeeachLaborGeneral from "@/components/employeeeach/labor/General.vue";
import EmployeeeachLaborPayment from "@/components/employeeeach/labor/Payment.vue";
import EmployeeeachLaborTaxins from "@/components/employeeeach/labor/Taxins.vue";
import EmployeeeachLaborPaidhol from "@/components/employeeeach/labor/Paidhol.vue";

@Component({
  components: {
    EmployeeeachLaborGeneral,
    EmployeeeachLaborPayment,
    EmployeeeachLaborTaxins,
    EmployeeeachLaborPaidhol,
  }
})
export default class Main extends Mixins(mixinEmployeeEach) {
  created() {
    this.m.initEditManager({name: 'labor_general'});
    this.m.initEditManager({name: 'payment'});
    this.m.initEditManager({name: 'taxins'});
    this.m.initEditManager({name: 'paidhol'});
  }
}
