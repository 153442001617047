import { render, staticRenderFns } from "./Paymentgroup.vue?vue&type=template&id=4da71b45&scoped=true&lang=pug"
import script from "./Paymentgroup.vue?vue&type=script&lang=ts"
export * from "./Paymentgroup.vue?vue&type=script&lang=ts"
import style0 from "./Paymentgroup.vue?vue&type=style&index=0&id=4da71b45&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4da71b45",
  null
  
)

export default component.exports