
import mixinPage from '@/mixins/mixinPage';
import { Component, Prop, Mixins } from 'vue-property-decorator';

@Component({
  components: {
  }
})
export default class Row extends Mixins(mixinPage) {
  @Prop() dependant;

  open_dependant_dialog() {
    this.$emit('openDependant', this.dependant.id);
  }
}
