
import { Provide } from '@/library/provide';
import mixinPage from '@/mixins/mixinPage';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import UtilPopup from "@/components/util/Popup.vue";
import LContent from "@/components/l/Content.vue";
import LBread from "@/components/l/Bread.vue";
import LTableBody from "@/components/l/Table/Body.vue";
import FCheck from "@/components/f/Check.vue";

@Component({
  components: {
    UtilPopup,
    LContent,
    LBread,
    LTableBody,
    FCheck,
  }
})
export default class Main extends Mixins(mixinPage) {
  @Prop() paymentgroup_hash: any;
  @Prop() belong_paymentgroup_ids: any;

  get raw_object() {
    return {belong_paymentgroup_ids: this.belong_paymentgroup_ids};
  }
  get tmp_object() {
    return this.manager.object.belong_paymentgroup;
  }

}
