
  import { Component, Mixins } from "vue-property-decorator";
  import { Alert, Space } from "ant-design-vue";
  import mixinEmployeeEach from "@/mixins/mixinEmployeeEach";
  import LProgress from "@/components/l/Progress.vue";
  import LContent from "@/components/l/Content.vue";
  import LBread from "@/components/l/Bread.vue";
  import LItemlevel from "@/components/l/Itemlevel.vue";
  import EmployeeeachLaborPaidholCard from "@/components/employeeeach/labor/paidhol/Card.vue";
  import LTable from "@/components/l/Table.vue";
  import EmployeeeachLaborPaidholEmployee from "@/components/employeeeach/labor/paidhol/Employee.vue";
  import LTableBody from "@/components/l/Table/Body.vue";
  import FControl from "@/components/f/Control.vue";
  import EmployeeeachLaborPaidholRow from "@/components/employeeeach/labor/paidhol/Row.vue";
  import LTableFoot from "@/components/l/Table/Foot.vue";
  import Icon from "@/components/CommonNew/Icon.vue";

  @Component({
    components: {
      LProgress,
      LContent,
      LBread,
      LItemlevel,
      EmployeeeachLaborPaidholCard,
      LTable,
      EmployeeeachLaborPaidholEmployee,
      LTableBody,
      FControl,
      EmployeeeachLaborPaidholRow,
      LTableFoot,
      AAlert: Alert,
      Space,
      Icon,
    },
  })
  export default class Main extends Mixins(mixinEmployeeEach) {
    created() {
      this.m.initAddManager({ name: "paidhol" });
      this.m.initEditManager({ name: "paidhol" });
      this.m.initEditManager({ name: "paidhol_employee" });
    }

    get add_paidhol_object() {
      return {
        date: null,
        value: 1,
        type: 3,
      };
    }

    get is_filled_employee_info() {
      return (
        this.employeely.employee.joined_on !== null &&
        this.employeely.employee.week_prescribed_day !== null &&
        this.employeely.employee.week_prescribed_hour !== null
      );
    }
  }
