
import { Provide } from '@/library/provide';
import mixinPage from '@/mixins/mixinPage';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import UtilPopup from "@/components/util/Popup.vue";
import LContent from "@/components/l/Content.vue";
import LBread from "@/components/l/Bread.vue";
import LTableBody from "@/components/l/Table/Body.vue";
import FRadio from "@/components/f/Radio.vue";

@Component({
  components: {
    UtilPopup,
    LContent,
    LBread,
    LTableBody,
    FRadio,
  }
})
export default class Main extends Mixins(mixinPage) {
  @Prop() branch_hash: any;
  @Prop() belong_main_branch_id: any;

  get raw_object() {
    return {belong_main_branch_id: this.belong_main_branch_id};
  }
  get tmp_object() {
    return this.manager.object.belong_main_branch;
  }
}
