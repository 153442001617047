
import { Component, Mixins, Prop } from 'vue-property-decorator';
import mixinPage from '@/mixins/mixinPage';

@Component({
  components: {
  }
})
export default class Row extends Mixins(mixinPage) {
  @Prop() spouce: any;
  open_spouce_dialog() {
    this.$emit('openSpouce', this.spouce.id);
  }
}
