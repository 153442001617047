
  import { Component, Prop, Mixins } from "vue-property-decorator";
  import mixinPage from "@/mixins/mixinPage";
  import { Provide } from "@/library/provide";
  import LContent from "@/components/l/Content.vue";
  import LBread from "@/components/l/Bread.vue";
  import EmployeeeachSelfBasePack from "@/components/employeeeach/self/base/Pack.vue";
  import FControl from "@/components/f/Control.vue";

  @Component({
    components: {
      LContent,
      LBread,
      EmployeeeachSelfBasePack,
      FControl,
    },
  })
  export default class Main extends Mixins(mixinPage) {
    @Prop() employee: any;
    @Prop() branch_hash: any;
    @Prop() global_config: any;

    created() {
      this.edit({ employee: this.raw_object });
    }

    get raw_object() {
      return this.employee;
    }
    get tmp_object() {
      return this.manager.object.employee;
    }

    editCancel() {
      this.cancel();
      this.page = "self_general";
    }

    @Provide("employee_type")
    get employee_type() {
      return this.employee.type;
    }
  }
