
  import { Component, Mixins, Prop } from "vue-property-decorator";
  import mixinPage from "@/mixins/mixinPage";
  import { Provide } from "@/library/provide";
  import LContent from "@/components/l/Content.vue";
  import LBread from "@/components/l/Bread.vue";
  import FControl from "@/components/f/Control.vue";
  import EmployeeeachConfigConfigIsuse from "@/components/employeeeach/config/config/Isuse.vue";
  import LItemlevel from "@/components/l/Itemlevel.vue";
  import ConfigAttendanceHolidayOuthol from "@/components/config/attendance/holiday/Outhol.vue";
  import ConfigAttendanceHolidayLawhol from "@/components/config/attendance/holiday/Lawhol.vue";
  import ConfigPaymentRateLawoverrate from "@/components/config/payment/rate/Lawoverrate.vue";
  import ConfigPaymentRatePreoverrate from "@/components/config/payment/rate/Preoverrate.vue";
  import ConfigPaymentRateSixtylawoverrate from "@/components/config/payment/rate/Sixtylawoverrate.vue";
  import ConfigPaymentRateOutholrate from "@/components/config/payment/rate/Outholrate.vue";
  import ConfigPaymentRateLawholrate from "@/components/config/payment/rate/Lawholrate.vue";
  import ConfigPaymentRateMidrate from "@/components/config/payment/rate/Midrate.vue";
  import ConfigAttendanceRoundPunch from "@/components/config/attendance/round/Punch.vue";
  import ConfigAttendanceRoundBreak from "@/components/config/attendance/round/Break.vue";
  import EmployeeeachConfigConfigOrderovertime from "@/components/employeeeach/config/config/Orderovertime.vue";
  import EmployeeeachConfigConfigOrderholiday from "@/components/employeeeach/config/config/Orderholiday.vue";
  import EmployeeeachConfigConfigOrderchange from "@/components/employeeeach/config/config/Orderchange.vue";
  import EmployeeeachConfigConfigOrderpunch from "@/components/employeeeach/config/config/Orderpunch.vue";
  import ConfigShiftDispShowothershift from "@/components/config/shift/disp/Showothershift.vue";
  import ConfigShiftDispShiftpunchsync from "@/components/config/shift/disp/Shiftpunchsync.vue";
  import ConfigAttendancePunchGps from "@/components/config/attendance/punch/Gps.vue";
  import ConfigAttendancePunchMypc from "@/components/config/attendance/punch/Mypc.vue";
  import ConfigPaymentSlipShowzero from "@/components/config/payment/slip/Showzero.vue";
  import ConfigPaymentSlipRemainpaidhol from "@/components/config/payment/slip/Remainpaidhol.vue";

  @Component({
    components: {
      LContent,
      LBread,
      FControl,
      EmployeeeachConfigConfigIsuse,
      LItemlevel,
      ConfigAttendanceHolidayOuthol,
      ConfigAttendanceHolidayLawhol,
      ConfigPaymentRateLawoverrate,
      ConfigPaymentRatePreoverrate,
      ConfigPaymentRateSixtylawoverrate,
      ConfigPaymentRateOutholrate,
      ConfigPaymentRateLawholrate,
      ConfigPaymentRateMidrate,
      ConfigAttendanceRoundPunch,
      ConfigAttendanceRoundBreak,
      EmployeeeachConfigConfigOrderovertime,
      EmployeeeachConfigConfigOrderholiday,
      EmployeeeachConfigConfigOrderchange,
      EmployeeeachConfigConfigOrderpunch,
      ConfigShiftDispShowothershift,
      ConfigShiftDispShiftpunchsync,
      ConfigAttendancePunchGps,
      ConfigAttendancePunchMypc,
      ConfigPaymentSlipShowzero,
      ConfigPaymentSlipRemainpaidhol,
    },
  })
  export default class Main extends Mixins(mixinPage) {
    @Prop() employee: any;
    @Prop() global_config: any;
    @Prop() employeely_config: any;

    get raw_object() {
      return this.employee;
    }
    get tmp_object() {
      return this.manager.object.employee;
    }

    @Provide("employee_type")
    get employee_type() {
      return this.employee.type;
    }

    get add_paidhol_object() {
      return {
        date: null,
        value: 1,
        type: 3,
      };
    }
  }
