
import { Component, Prop, Mixins } from 'vue-property-decorator';
import mixinPage from '@/mixins/mixinPage';
import FDate from "@/components/f/Date.vue";
import FSelect from "@/components/f/Select.vue";
import FInfo from "@/components/f/Info.vue";
import FText from "@/components/f/Text.vue";

@Component({
  components: {
    FDate,
    FSelect,
    FInfo,
    FText,
  }
})
export default class Row extends Mixins(mixinPage) {
  @Prop() paid_holiday;
}
