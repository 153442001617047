
import mixinPart from '@/mixins/mixinPart';
import { Component, Prop, Mixins } from 'vue-property-decorator';
import LItem from "@/components/l/Item.vue";
import FSelect from "@/components/f/Select.vue";
import FControl from "@/components/f/Control.vue";
import EmployeeeachBelongDialogMainbranch from "@/components/employeeeach/belong/dialog/Mainbranch.vue";

@Component({
  components: {
    LItem,
    FSelect,
    FControl,
    EmployeeeachBelongDialogMainbranch,
  }
})
export default class Main extends Mixins(mixinPart) {
  @Prop() manager: any;
  @Prop() branch_hash: any;
  @Prop() belong_main_branch_id: any;
  @Prop() global_config: any;
  @Prop() employeely_config: any;

  created() {
    this._title = 'main_branch';
    this._info = 'info.main_branch';
    this._popup_title = 'belong_branch';
    this._popup_content = 'popup.branch';
  }

  update() {
    this.$emit('update', {tmp_objects: this.manager.object});
  }
}
