
import { Component, Mixins, Prop } from 'vue-property-decorator';
import mixinPage from '@/mixins/mixinPage';
import { Provide } from '@/library/provide';
import LDialog from "@/components/l/Dialog.vue";
import LContent from "@/components/l/Content.vue";
import LBread from "@/components/l/Bread.vue";
import EmployeeeachDialogMailOldmail from "@/components/employeeeach/dialog/mail/Oldmail.vue";
import EmployeeeachDialogMailNewmail from "@/components/employeeeach/dialog/mail/Newmail.vue";

@Component({
  components: {
    LDialog,
    LContent,
    LBread,
    EmployeeeachDialogMailOldmail,
    EmployeeeachDialogMailNewmail,
  }
})
export default class Main extends Mixins(mixinPage) {
  @Prop() employee: any;

  get is_display() {
    return !!this.manager.flag;
  }
  set is_display(val: boolean) {
    if (!val) this.manager.cancel();
  }

  get raw_object() {
    return this.employee;
  }
  get tmp_object() {
    return this.manager.object.employee;
  }
}
