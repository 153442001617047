
  import { Component, Prop, Mixins } from "vue-property-decorator";
  import mixinPage from "@/mixins/mixinPage";
  import { Provide } from "@/library/provide";
  import FDate from "@/components/f/Date.vue";
  import FText from "@/components/f/Text.vue";
  import FSelect from "@/components/f/Select.vue";
  import FInfo from "@/components/f/Info.vue";
  import FControl from "@/components/f/Control.vue";

  @Component({
    components: {
      FDate,
      FText,
      FSelect,
      FInfo,
      FControl,
    },
  })
  export default class Main extends Mixins(mixinPage) {
    @Prop() paidhol: any;
    @Prop() is_paidhol_bank: boolean;
    @Prop() work_ins_kinds: any;
    @Prop() work_ins_types: any;
    @Prop() paidhol_employees: any;
    @Prop() global_config: any;
    @Prop() employeely_config: any;

    get raw_object() {
      return this.paidhol;
    }
    get tmp_object() {
      return this.manager.object.paidhol;
    }

    @Provide("is_edit")
    get is_edit() {
      return this.raw_object && this.manager.flag == this.raw_object.id;
    }

    belong_num(paidhol_id) {
      return this.paidhol_employees[paidhol_id] ? this.paidhol_employees[paidhol_id].length : 0;
    }
    on_change_work_ins_kind_id(val) {
      const work_ins_type_id = Object.keys(this.work_ins_types[val])[0];
      this.object.work_ins_type_id = work_ins_type_id;
    }
  }
