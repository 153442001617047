
  import { Component, Mixins, Prop } from "vue-property-decorator";
  import mixinEmployeeEach from "@/mixins/mixinEmployeeEach";
  import LProgress from "@/components/l/Progress.vue";
  import LContent from "@/components/l/Content.vue";
  import LBread from "@/components/l/Bread.vue";
  import LTable from "@/components/l/Table.vue";
  import EmployeeeachInfoInfoRow from "@/components/employeeeach/info/info/Row.vue";
  import EmployeeeachInfoInfoImagerow from "@/components/employeeeach/info/info/Imagerow.vue";

  @Component({
    components: {
      LProgress,
      LContent,
      LBread,
      LTable,
      EmployeeeachInfoInfoRow,
      EmployeeeachInfoInfoImagerow,
    },
  })
  export default class Main extends Mixins(mixinEmployeeEach) {
    created() {
      this.m.initEditManager({ name: "info" });
      this.m.initEditManager({ name: "infoimage" });
    }

    get add_paidhol_object() {
      return {
        date: null,
        value: 1,
        type: 3,
      };
    }
  }
