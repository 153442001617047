
  import { Component, Prop, Mixins } from "vue-property-decorator";
  import mixinPage from "@/mixins/mixinPage";
  import Earning from "@/entities/Earning";
  import * as util from "@/util";
  import { Provide } from "@/library/provide";
  import LContent from "@/components/l/Content.vue";
  import LBread from "@/components/l/Bread.vue";
  import FControl from "@/components/f/Control.vue";
  import EmployeeeachSelfFamilyDependantName from "@/components/employeeeach/self/family/dependant/Name.vue";
  import EmployeeeachSelfFamilyDependantBirthdate from "@/components/employeeeach/self/family/dependant/Birthdate.vue";
  import EmployeeeachSelfFamilyDependantMynum from "@/components/employeeeach/self/family/dependant/Mynum.vue";
  import EmployeeeachSelfFamilyDependantRelationship from "@/components/employeeeach/self/family/dependant/Relationship.vue";
  import EmployeeeachSelfFamilyDependantHousemate from "@/components/employeeeach/self/family/dependant/Housemate.vue";
  import EmployeeeachSelfFamilyDependantHousematespouce from "@/components/employeeeach/self/family/dependant/Housematespouce.vue";
  import EmployeeeachSelfFamilyDependantAddress from "@/components/employeeeach/self/family/dependant/Address.vue";
  import EmployeeeachSelfFamilyDependantNotresident from "@/components/employeeeach/self/family/dependant/Notresident.vue";
  import EmployeeeachSelfFamilyDependantRemittance from "@/components/employeeeach/self/family/dependant/Remittance.vue";
  import EmployeeeachSelfFamilyDependantIncome from "@/components/employeeeach/self/family/dependant/Income.vue";
  import EmployeeeachSelfFamilyDependantDisability from "@/components/employeeeach/self/family/dependant/Disability.vue";
  import EmployeeeachSelfFamilyDependantDisabilityreason from "@/components/employeeeach/self/family/dependant/Disabilityreason.vue";
  import EmployeeeachSelfFamilyDependantHousematelivelihood from "@/components/employeeeach/self/family/dependant/Housematelivelihood.vue";
  import EmployeeeachSelfFamilyDependantInternationalstudent from "@/components/employeeeach/self/family/dependant/Internationalstudent.vue";

  @Component({
    components: {
      LContent,
      LBread,
      FControl,
      EmployeeeachSelfFamilyDependantName,
      EmployeeeachSelfFamilyDependantBirthdate,
      EmployeeeachSelfFamilyDependantMynum,
      EmployeeeachSelfFamilyDependantRelationship,
      EmployeeeachSelfFamilyDependantHousemate,
      EmployeeeachSelfFamilyDependantHousematespouce,
      EmployeeeachSelfFamilyDependantAddress,
      EmployeeeachSelfFamilyDependantNotresident,
      EmployeeeachSelfFamilyDependantRemittance,
      EmployeeeachSelfFamilyDependantIncome,
      EmployeeeachSelfFamilyDependantDisability,
      EmployeeeachSelfFamilyDependantDisabilityreason,
      EmployeeeachSelfFamilyDependantHousematelivelihood,
      EmployeeeachSelfFamilyDependantInternationalstudent,
    },
  })
  export default class Main extends Mixins(mixinPage) {
    @Prop() employee: any;
    @Prop() dependant: any;
    @Prop() is_spouce: boolean;
    @Prop() global_config: any;
    @Prop() employeely_config: any;

    created() {
      if (!this.dependant) {
        this.add({ dependant: this.add_dependant_object });
      }
    }

    addCancel() {
      this.cancel();
      this.page = "self_family";
    }

    get add_dependant_object() {
      return {
        employee_id: this.employee.id,
        name: null,
        kana: null,
        birth_date: null,
        mynum: null,
        dependant_relationship_id: null,
        is_housemate: 1,
        disability_type: 0,
        income: 0,
        retire_income: 0,
        other_income: 0,
        is_not_live: 0,
        is_child_allowance: 0,
        is_special_disability_housemate: 1,
        is_old_spouce_housemate: null,
        is_international_student: 0,
        address: null,
        disability_reason: null,
        not_live_money: null,
      };
    }

    get raw_object() {
      return this.dependant;
    }
    get tmp_object() {
      return this.manager.object.dependant;
    }

    @Provide("is_edit")
    get is_edit() {
      return this.manager.flag && this.dependant;
    }
    @Provide("is_add")
    get is_add() {
      return this.manager.flag && !this.dependant;
    }
  }
