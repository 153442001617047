
import { Component, Prop, Mixins } from 'vue-property-decorator';
import mixinPage from '@/mixins/mixinPage';
import { Provide } from '@/library/provide';
import LContent from "@/components/l/Content.vue";
import LBread from "@/components/l/Bread.vue";
import FControl from "@/components/f/Control.vue";
import EmployeeeachSelfFamilySpouceName from "@/components/employeeeach/self/family/spouce/Name.vue";
import EmployeeeachSelfFamilySpouceBirthdate from "@/components/employeeeach/self/family/spouce/Birthdate.vue";
import EmployeeeachSelfFamilySpouceMynum from "@/components/employeeeach/self/family/spouce/Mynum.vue";
import EmployeeeachSelfFamilySpouceHousemate from "@/components/employeeeach/self/family/spouce/Housemate.vue";
import EmployeeeachSelfFamilySpouceAddress from "@/components/employeeeach/self/family/spouce/Address.vue";
import EmployeeeachSelfFamilySpouceNotresident from "@/components/employeeeach/self/family/spouce/Notresident.vue";
import EmployeeeachSelfFamilySpouceRemittance from "@/components/employeeeach/self/family/spouce/Remittance.vue";
import EmployeeeachSelfFamilySpouceIncome from "@/components/employeeeach/self/family/spouce/Income.vue";
import EmployeeeachSelfFamilySpouceDisability from "@/components/employeeeach/self/family/spouce/Disability.vue";
import EmployeeeachSelfFamilySpouceDisabilityreason from "@/components/employeeeach/self/family/spouce/Disabilityreason.vue";
import EmployeeeachSelfFamilySpouceHousematelivelihood from "@/components/employeeeach/self/family/spouce/Housematelivelihood.vue";

@Component({
  components: {
    LContent,
    LBread,
    FControl,
    EmployeeeachSelfFamilySpouceName,
    EmployeeeachSelfFamilySpouceBirthdate,
    EmployeeeachSelfFamilySpouceMynum,
    EmployeeeachSelfFamilySpouceHousemate,
    EmployeeeachSelfFamilySpouceAddress,
    EmployeeeachSelfFamilySpouceNotresident,
    EmployeeeachSelfFamilySpouceRemittance,
    EmployeeeachSelfFamilySpouceIncome,
    EmployeeeachSelfFamilySpouceDisability,
    EmployeeeachSelfFamilySpouceDisabilityreason,
    EmployeeeachSelfFamilySpouceHousematelivelihood,
  }
})
export default class Main extends Mixins(mixinPage) {
  @Prop() employee: any;
  @Prop() spouce: any;
  @Prop() global_config: any;
  @Prop() employeely_config: any;

  created() {
    if (!this.spouce) {
      this.add({spouce: this.add_spouce_object});
    }
  }

  addCancel() {
    this.cancel();
    this.page = 'self_family'
  }

  get add_spouce_object() {
    return {
      employee_id: this.employee.id, 
      kana: null, 
      name: null, 
      birth_date: null,
      mynum: null, 
      is_housemate: 1, 
      disability_type: 0, 
      income: 0, 
      retire_income: 0, 
      other_income: 0, 
      is_not_live: 0, 
      address: null, 
      not_live_money: null,
      is_special_disability_housemate: 1,
      disability_reason: null,
    };
  }


  get raw_object() {
    return this.spouce;
  }
  get tmp_object() {
    return this.manager.object.spouce;
  }

  @Provide('is_edit')
  get is_edit() {
    return this.manager.flag && this.spouce;
  }
  @Provide('is_add')
  get is_add() {
    return this.manager.flag && !this.spouce;
  }
}
