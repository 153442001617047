
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import mixinPart from '@/mixins/mixinPart';
import LItem from "@/components/l/Item.vue";
import FImg from "@/components/f/Img.vue";

@Component({
  components: {
    LItem,
    FImg,
  }
})
export default class Main extends Mixins(mixinPart) {
  @Prop() employee_info: any;
  @Prop() raw_object: any;
  @Prop() is_valid: boolean;

  get initial_image() {
    return this.raw_object[0].image ?? null;
  }


  @Watch('is_edit')
  onChangeIsEdit(newval) {
    if (newval) {
      this.input_each_employee_info();
    }
  }

  input_each_employee_info() {
    let last_index = this.object.length - 1;
    if (last_index == -1) {
      this.object.push({image: null});
    }
  }

  edit(val) {
    this.$emit('edit', val);
  }
  update() {
    this.$emit('update');
  }
  cancel() {
    this.$emit('cancel');
  }
}
