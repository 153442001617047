
import { Component, Mixins } from 'vue-property-decorator';
import mixinEmployeeEach from '@/mixins/mixinEmployeeEach';
import LProgress from "@/components/l/Progress.vue";
import EmployeeeachBelongBelong from "@/components/employeeeach/belong/Belong.vue";

@Component({
  components: {
    LProgress,
    EmployeeeachBelongBelong,
  }
})
export default class Main extends Mixins(mixinEmployeeEach) {
}
