
import { Component, Prop, Mixins } from 'vue-property-decorator';
import mixinPage from '@/mixins/mixinPage';
import FButton from "@/components/f/Button.vue";

@Component({
  components: {
    FButton,
  }
})
export default class Main extends Mixins(mixinPage) {
  @Prop() employee_id: number;
  @Prop() spouce: any;
  @Prop() global_config: any;
  @Prop() employeely_config: any;

  openSpouce(spouce_id: number) {
    this.$emit('openSpouce', spouce_id);
  }
}
