import { render, staticRenderFns } from "./Spoucerow.vue?vue&type=template&id=68d4017f&scoped=true&lang=pug"
import script from "./Spoucerow.vue?vue&type=script&lang=ts"
export * from "./Spoucerow.vue?vue&type=script&lang=ts"
import style0 from "./Spoucerow.vue?vue&type=style&index=0&id=68d4017f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68d4017f",
  null
  
)

export default component.exports