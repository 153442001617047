
import { Component, Mixins } from 'vue-property-decorator';
import mixinEmployeeEach from '@/mixins/mixinEmployeeEach';
import EmployeeeachMain from "@/components/employeeeach/Main.vue";

@Component({
  components: {
    EmployeeeachMain,
  }
})
export default class Main extends Mixins(mixinEmployeeEach) {
  
}
