
import { Component, Mixins } from 'vue-property-decorator';
import mixinEmployeeEach from '@/mixins/mixinEmployeeEach';
import EmployeeeachInfoInfo from "@/components/employeeeach/info/Info.vue";

@Component({
  components: {
    EmployeeeachInfoInfo,
  }
})
export default class Main extends Mixins(mixinEmployeeEach) {
  created() {
  }
}
