
import { Component, Prop, Mixins, Vue } from 'vue-property-decorator';
import mixinPage from '@/mixins/mixinPage';
import { Provide } from '@/library/provide';
import LContent from "@/components/l/Content.vue";
import LBread from "@/components/l/Bread.vue";
import FControl from "@/components/f/Control.vue";
import EmployeeeachLaborPaymentPack from "@/components/employeeeach/labor/payment/Pack.vue";

@Component({
  components: {
    LContent,
    LBread,
    FControl,
    EmployeeeachLaborPaymentPack,
  }
})
export default class Main extends Mixins(mixinPage) {
  @Prop() employee: any;
  @Prop() global_config: any;
  @Prop() employeely_config: any;
  @Prop() individual_units: any;

  get raw_object() {
    return this.employee;
  }
  get tmp_object() {
    return this.manager.object.employee;
  }

  @Provide('employee_type')
  get employee_type() {
    return this.employee.type;
  }
}
