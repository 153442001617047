
import { Component, Mixins } from 'vue-property-decorator';
import mixinEmployeeEach from '@/mixins/mixinEmployeeEach';
import { Provide } from '@/library/provide';
import LProgress from "@/components/l/Progress.vue";
import EmployeeeachSelfGeneral from "@/components/employeeeach/self/General.vue";
import EmployeeeachSelfBank from "@/components/employeeeach/self/Bank.vue";
import EmployeeeachSelfTax from "@/components/employeeeach/self/Tax.vue";
import EmployeeeachSelfFamily from "@/components/employeeeach/self/Family.vue";
import EmployeeeachSelfFamilySpouce from "@/components/employeeeach/self/family/Spouce.vue";
import EmployeeeachSelfFamilyDependant from "@/components/employeeeach/self/family/Dependant.vue";
import EmployeeeachSelfBase from "@/components/employeeeach/self/Base.vue";
import EmployeeeachDialogMail from "@/components/employeeeach/dialog/Mail.vue";
import EmployeeeachDialogPass from "@/components/employeeeach/dialog/Pass.vue";

@Component({
  components: {
    LProgress,
    EmployeeeachSelfGeneral,
    EmployeeeachSelfBank,
    EmployeeeachSelfTax,
    EmployeeeachSelfFamily,
    EmployeeeachSelfFamilySpouce,
    EmployeeeachSelfFamilyDependant,
    EmployeeeachSelfBase,
    EmployeeeachDialogMail,
    EmployeeeachDialogPass,
  }
})
export default class Main extends Mixins(mixinEmployeeEach) {
  created() {
    this.m.initEditManager({name: 'self_general'});
    this.m.initEditManager({name: 'bank'});
    this.m.initEditManager({name: 'tax'});
    this.m.initEditManager({name: 'family'});

    this.m.initEditManager({name: 'spouce'});
    this.m.initAddManager({name: 'spouce'});
    this.m.initEditManager({name: 'dependant'});
    this.m.initAddManager({name: 'dependant'});

    this.m.initEditManager({name: 'base'});

    this.m.initEditManager({name: 'mail'});
    this.m.initEditManager({name: 'pass'});
  }
}
