
import { Component, Mixins, Prop } from 'vue-property-decorator';
import mixinPage from '@/mixins/mixinPage';
import { Provide } from '@/library/provide';
import LDialog from "@/components/l/Dialog.vue";
import LContent from "@/components/l/Content.vue";
import LBread from "@/components/l/Bread.vue";
import EmployeeeachDialogPassOldpass from "@/components/employeeeach/dialog/pass/Oldpass.vue";
import EmployeeeachDialogPassNewpass from "@/components/employeeeach/dialog/pass/Newpass.vue";
import EmployeeeachDialogPassNewpassconf from "@/components/employeeeach/dialog/pass/Newpassconf.vue";

@Component({
  components: {
    LDialog,
    LContent,
    LBread,
    EmployeeeachDialogPassOldpass,
    EmployeeeachDialogPassNewpass,
    EmployeeeachDialogPassNewpassconf,
  }
})
export default class Main extends Mixins(mixinPage) {
  @Prop() employee: any;

  get is_display() {
    return !!this.manager.flag;
  }
  set is_display(val: boolean) {
    if (!val) this.manager.cancel();
  }

  get raw_object() {
    return this.employee;
  }
  get tmp_object() {
    return this.manager.object.employee;
  }
}
