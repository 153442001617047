
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import mixinPart from '@/mixins/mixinPart';
import { Inject } from '@/library/provide';
import LItem from "@/components/l/Item.vue";
import FRadio from "@/components/f/Radio.vue";

@Component({
  components: {
    LItem,
    FRadio,
  }
})
export default class Main extends Mixins(mixinPart) {
  @Prop() employee_info: any;

  @Watch('is_edit')
  onChangeIsEdit(newval) {
    if (newval) {
      this.input_each_employee_info();
    }
  }

  input_each_employee_info() {
    let last_index = this.object.length - 1;
    if (last_index == -1) {
      this.object.push({option: null});
    }
  }
}
