
import { Component, Mixins } from 'vue-property-decorator';
import mixinEmployeeEach from '@/mixins/mixinEmployeeEach';
import * as util from '@/util';
import LProgress from "@/components/l/Progress.vue";
import LPage from "@/components/l/Page.vue";
import EmployeeeachCard from "@/components/employeeeach/Card.vue";
import LSidemenu from "@/components/l/Sidemenu.vue";
import FSidemenu from "@/components/f/Sidemenu.vue";
import EmployeeeachSelfMain from "@/components/employeeeach/self/Main.vue";
import EmployeeeachLaborMain from "@/components/employeeeach/labor/Main.vue";
import EmployeeeachInfoMain from "@/components/employeeeach/info/Main.vue";
import EmployeeeachBelongMain from "@/components/employeeeach/belong/Main.vue";
import EmployeeeachConfigMain from "@/components/employeeeach/config/Main.vue";
import EmployeeeachDialogMypc from "@/components/employeeeach/dialog/Mypc.vue";

@Component({
  components: {
    LProgress,
    LPage,
    EmployeeeachCard,
    LSidemenu,
    FSidemenu,
    EmployeeeachSelfMain,
    EmployeeeachLaborMain,
    EmployeeeachInfoMain,
    EmployeeeachBelongMain,
    EmployeeeachConfigMain,
    EmployeeeachDialogMypc,
  }
})
export default class Main extends Mixins(mixinEmployeeEach) {
  created() {
    this.m.initEditManager({name: 'card'});
    this.m.initEditManager({name: 'mypc'});
  }

  async registMypc({id, tmp_objects}) {
    let token = util.random(10);
    const res = await this.m.update({name: 'mypc', args: {employee: tmp_objects.employee, employee_id: this.employee_id, token: token}})
    if (res) {
      util.setLocalStorage('mypc', token);
    }
  }

  get is_prev_employee() {
    return this.employeely.prev_employee_id != null;
  }
  get is_next_employee() {
    return this.employeely.next_employee_id != null;
  }
  prev_employee() {
    this.employee_id = this.employeely.prev_employee_id;
  }
  next_employee() {
   this.employee_id = this.employeely.next_employee_id;
  }
}
