
import { Component, Prop, Mixins } from 'vue-property-decorator';
import mixinPage from '@/mixins/mixinPage';
import { Provide } from '@/library/provide';
import EmployeeeachInfoInfoImage from "@/components/employeeeach/info/info/image.vue";

@Component({
  components: {
    EmployeeeachInfoInfoImage,
  }
})
export default class Main extends Mixins(mixinPage) {
  @Prop() employee_info: any;
  @Prop() each_employee_infos: any;
  @Prop() global_config: any;
  @Prop() employeely_config: any;

  get raw_object() {
    return this.each_employee_infos;
  }
  get tmp_object() {
    return this.manager.object.each_employee_infos;
  }

  @Provide('is_edit')
  get is_edit() {
    return this.manager.flag == this.employee_info.id;
  }
  @Provide('is_add')
  get is_add() {
    return false;
  }
}
